import React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { users } from "../../api/users";
import { setShowLoader, setStripeClientId } from "../../redux/actions/app";
import { setCurrentUser } from "../../redux/actions/user";
import Login from "../Login/Login";

const ProtectedComponent = (props) => {
    const { requested } = props;
    const dispatch = useDispatch();
    const [redirectToLogin, setRedirectToLogin] = React.useState(false);

    useEffect(() => {
        dispatch(
            users({
                isBackground: true,
                param_path: "current",
            }),
        )
            .then((response) => {
                if (response.success) {
                    dispatch(setCurrentUser(response.data.user));
                    dispatch(setStripeClientId(response.data.stripe_client_id));
                }
            })
            .catch((e) => {
                console.log(e);
                setRedirectToLogin(true);
            });
    }, [dispatch]);
    return (
        <>
            {redirectToLogin && <Login requested={requested} />}
            {!redirectToLogin && <div></div>}
        </>
    );
};

export default ProtectedComponent;
