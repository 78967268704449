import { LOGIN, SET_TOKEN, LOGOUT, NEEDS_LOGIN } from "../constants/user";

export const setCurrentUser = (user) => {
    return {
        type: LOGIN,
        user,
    }
}

export const setToken = (token) => {
    return {
        type: SET_TOKEN,
        token
    }
}

export const logoutUser = () => {
    return {
        type: LOGOUT
    }
}

export const redirectToLogin = () => {
    return {
        type: NEEDS_LOGIN,
        payload: true
    }
}

export const redirected = () => {
    return {
        type: NEEDS_LOGIN,
        payload: false
    }
}