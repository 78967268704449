import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { login, onSuccessLogin, registerUser, socialLogin } from "../../api/users";
import styles from "./login.module.css";
import { getAuth, GoogleAuthProvider, signInWithPopup, FacebookAuthProvider } from "@firebase/auth";
import ThemeLayout from "../../layout/themeLayout";
import Button from "component/Button/Button";
import Joi from "joi";
import { useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { passwordRegex, usernameRegex } from "../../helpers/regex";
import { passwordValidationSet, setErrorMessages, usernameValidationSet, validateRequired } from "../../helpers/validationMessages";
import { redirected } from "../../redux/actions/user";
import Error from "../../component/Error/Error";
import { toast } from "react-toastify";
import { apiCall } from "../../helpers/api";
import { Modal } from "react-bootstrap";
import Input from "../../component/Input/Input";
import playstore from './play-store.png';
import appstore from './app-store.svg';
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

const Login = React.memo((props) => {
    const [showSignup, setShowSignup] = useState(false);
    const [showLoginPassword, setShowLoginPassword] = useState(false);
    const [showSignupPassword, setShowSignupPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
    // const [isAgreed, setIsAgreed] = useState(false);

    // const checkisAgreed = () => {
    //     setIsAgreed((p) => !p);
    // };

    const [errors, setErrors] = useState({});

    const history = useHistory();

    const emailRef = useRef();
    const resetEmailRef = useRef();
    const passwordRef = useRef();
    const btnResetPassword = useRef();

    const signUpEmail = useRef();
    const signUpPassword = useRef();
    const confirmPassword = useRef();
    const signUpConfirmationPassword = useRef();
    const userName = useRef();

    const loginBtn = useRef();
    const registerBtn = useRef();

    const loginImg = useRef();

    const isAgreed = useRef();

    const signupFormRef = useRef();
    const resetFormRef = useRef();

    const [selectedUserType, setSelectedUserType] = useState("user");
    const dispatch = useDispatch();

    useEffect(() => {
        if (history.location.pathname == "/register") {
            setShowSignup(true);
        }
    }, [history, showSignup]);

    useEffect(() => {
        dispatch(redirected());
    }, [dispatch]);

    const { user, token } = useSelector((store) => {
        return {
            user: store.user.user,
            token: store.user.token,
        };
    });

    const toggleResetPasswordModal = () => {
        setShowResetPasswordModal((p) => !p);
    };

    const resetPassword = (e) => {
        e.preventDefault();
        setErrors({});

        // validate the form
        const data = {
            email: resetEmailRef?.current?.value,
        };

        const { error } = resetFormSchema.validate(data, {
            abortEarly: false,
        });

        if (error) {
            const t = setErrorMessages(error);
            console.log(error.details);
            return setErrors(t);
        }
        // submit the request
        dispatch(
            apiCall({
                path: `reset-password`,
                method: "POST",
                data,
            })
        )
            .then((response) => {
                if (response.success) {
                    toast.success(response.message);
                    setShowResetPasswordModal(false);
                } else {
                    toast.error(response.message);
                }
            })
            .catch((err) => {
                console.log(err);
                setShowResetPasswordModal(false);
            });
    };

    const onLogin = useCallback(
        async (e) => {
            e.preventDefault();
            setErrors({});
            const params = {
                data: {
                    email: emailRef.current?.value,
                    password: passwordRef.current?.value,
                },
            };

            const { error } = loginFormSchema.validate(params.data, {
                abortEarly: false,
            });

            if (error) {
                const t = setErrorMessages(error);
                return setErrors(t);
            }

            loginBtn.current?.showLoader(true);
            try {
                const response = await dispatch(login(params));

                if (!response.success && response.data.message) {
                    const messagesArray = response?.data?.message || {};
                    const t = setErrorMessages(messagesArray, true);
                    setErrors(t);
                }
            } catch (e) {
                const messages = e.response?.data?.data?.message;
                const messagesArray = e.response?.data?.data?.message?.error || {};
                const t = setErrorMessages(messagesArray, true);
                setErrors(t);

                if (messages.error) {
                    toast.error(messages.error);

                    if (e.response.data.message == "verify_email") {
                        localStorage.setItem("verify-email", emailRef.current?.value);
                        history.replace("/resend/email");
                    }
                }
            }
            loginBtn.current?.showLoader(false);
        },
        [dispatch]
    );

    const selectUserType = (type) => {
        if (type == "user") {
            setSelectedUserType("user");
            return;
        }
        if (type == "hunter") {
            setSelectedUserType("hunter");
            return;
        }
    };

    const location = useLocation();
    
    const onRegister = (e) => {
        e.preventDefault();
        setErrors({});
        
        const queryParams = new URLSearchParams(location.search);
        const utm = {}

        if (queryParams.get('utm_source'))
            utm.source = queryParams.get('utm_source');

        if (queryParams.get('utm_medium'))
            utm.medium = queryParams.get('utm_medium');
        
        if (queryParams.get('utm_campaign'))
            utm.campaign = queryParams.get('utm_campaign');

        const params = {
            data: {
                username: userName.current?.value,
                email: signUpEmail.current?.value,
                password: signUpPassword.current?.value,
                confirm_password: confirmPassword.current?.value,
                nature: selectedUserType,
                utm: utm
            },
        };

        const { error } = registerFormSchema.validate(params.data, { abortEarly: false });

        if (error) {
            const t = setErrorMessages(error);
            setErrors(t);
        }

        if (params.data.password !== params.data.confirm_password && !errors.confirm_password) {
            return setErrors({ ...errors, confirm_password: "Passwords do not match" });
        }

        if (!/[a-zA-Z]/.test(params.data.username)) {
            return setErrors({ ...errors, username: "Username should have at least one letter" });
        }

        if (!isAgreed.current?.checked) {
            setErrors({ ...errors, is_agreed: "Please agree to terms and conditions" });
            return;
        }

        registerBtn.current?.showLoader(true);
        dispatch(registerUser(params))
            // console.log("kabir", params)
            .then((response) => {
                localStorage.setItem("verify-email", signUpEmail.current?.value);
                history.replace("/resend/email");
                if (!response.success && response.data.message) {
                    const messagesArray = response?.data?.message || {};
                    const t = setErrorMessages(messagesArray, true);
                    setErrors(t);
                } else {
                    setShowSignup(false);
                }
            })
            .catch((e) => {
                const t = setErrorMessages(e, true);
                setErrors(t);
            });
        registerBtn.current?.showLoader(false);
    };

    const createParams = ({ providerData }, provider) => {
        let data = providerData[0];

        const params = {
            data: {
                uid: data.uid,
                name: data.displayName || data.name,
                email: data.email,
                profile_pic: data.photoURL,
                provider,
                is_social: 1,
            },
        };

        return params;
    };

    const onGoogleLogin = useCallback(async () => {
        const auth = new GoogleAuthProvider();
        auth.setCustomParameters({
            prompt: "select_account",
        });
        try {
            await signInWithPopup(getAuth(), auth);

            if (getAuth().currentUser) {
                dispatch(socialLogin(createParams(getAuth().currentUser, "google")))
                    .then((response) => {
                        if (response.success) {
                            dispatch(onSuccessLogin(response));
                        } else {
                            toast.error(response.message);
                        }
                    })
                    .catch((error) => {
                        toast.error(error.response.data.message);
                    });
            }
        } catch (error) {
            if (error.code == "auth/account-exists-with-different-credential") {
                toast.error("Your email address is already associated with your Facebook Account. Try to login with Facebook");
            } else {
                console.log(error);
            }
        }
    }, []);

    useEffect(() => {
        if (token || user.id) {
            if (props.requested) {
                return history.replace(props.requested);
            }
            return history.replace("/");
        }
    }, [history, token, user]);

    const toggleForm = () => {
        setShowSignup((p) => !p);
        setErrors({});
    };

    const saveFacebookProfileDetails = () => {
        window.FB.api("/me", { fields: "name,email" }, (response) => {
            let data = {
                uid: response.id,
                name: response.name,
                email: response.email,
            };

            window.FB.api(`/${data.uid}/picture`, "GET", { redirect: false }, (response) => {
                data = { ...data, profile_pic: response.data.url };
                data.providerData = [data];
                dispatch(socialLogin(createParams(data, "facebook")))
                    .then((response) => {
                        if (response.success) {
                            dispatch(onSuccessLogin(response));
                        } else {
                            toast.error(response.message);
                        }
                    })
                    .catch((error) => {
                        toast.error(error.response.data.message);
                    });
            });

            console.log(data);
        });
    };

    const onFacebookLogin = () => {
        window.FB.login(
            (response) => {
                if (response.status == "connected") {
                    saveFacebookProfileDetails();
                } else {
                    console.log(response)
                }
            },
            { scope: "public_profile,email", auth_type: "reauthenticate" }
        );
    };
    // const auth = new FacebookAuthProvider();
    // auth.setCustomParameters({
    //     auth_type: "reauthenticate",
    // });
    // try {
    //     await signInWithPopup(getAuth(), auth);

    //     if (getAuth().currentUser) {
    //         dispatch(socialLogin(createParams(getAuth().currentUser, "facebook")))
    //             .then((response) => {
    //                 dispatch(onSuccessLogin(response));
    //             })
    //             .catch((error) => {
    //                 console.log(error);
    //             });
    //     }
    // } catch (error) {
    //     if (error.code == "auth/account-exists-with-different-credential") {
    //         toast.error("Your email address is already associated with your Google Account. Try to login with Google");
    //     } else {
    //         console.log(error);
    //     }
    // }

    useEffect(() => {
        passwordRef.current?.focus();
        emailRef.current?.focus();
    }, []);

    return (
        <div className="d-flex flex-column justify-items-center h-100">
            <ThemeLayout showHeader={false} className={`${styles["md-vh-100"]} d-md-flex flex-column gray-bg`}>
                <Modal size="lg" id="submit-modal" show={showResetPasswordModal} onHide={() => toggleResetPasswordModal()} backdrop="static">
                    <Modal.Header className="p-3 border-bottom">
                        <h4 className="modal-title">Reset Password</h4>
                        <Button className="close" type="link" onClick={() => toggleResetPasswordModal()}>
                            x
                        </Button>
                    </Modal.Header>
                    <Modal.Body>
                        <form ref={resetFormRef}>
                            {/* <div className={`${styles.form__helper}`}>
                                <input ref={resetEmailRef} type="email" name="email" id="resetEmail" placeholder="Email" className={`${styles.form__input}`} />
                                <label className={`${styles.form__label}`} htmlFor="resetEmail">
                                    Email
                                </label>
                            </div> */}
                            <div className={`col-lg-12`}>
                                <Input className={`uk-input form-control`} type="email" ref={resetEmailRef} name="email" styleType="floating" placeholder="Email" />
                                {errors["email"] && <Error marginTop="-12px" error={errors["email"]} />}
                            </div>
                            {/* {errors["email"] && <Error error={errors["email"]} />} */}
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button type="button" ref={btnResetPassword} className="btn btn-primary" onClick={resetPassword}>
                            Reset
                        </Button>
                        <Button type="button" className="btn btn-secondary" onClick={() => toggleForm()}>
                            No
                        </Button>
                    </Modal.Footer>
                </Modal>
                <div className={`container ${styles.container} d-md-flex flex-column align-items-center mt-auto`} style={{ height: "100%" }}>
                    <div className={`row-sm ${styles.row} justify-content-md-center ${styles["justify-content-md-center"]}`}>
                        <div className={`col-lg-8 ${styles["col-lg-8"]}`}>
                            {/* <div className={`${styles["logo-up"]} mb-1`}>

                                <Link to="/">
                                    <figure className={`${styles.logo} mb-0`}>
                                        <img alt="" src="/public/images/resources/logo.png" />
                                        <span>Reverie</span>
                                    </figure>
                                </Link>
                                <hr className="center-ball-separator-hr" />
                                <div className="content mb-3">
                                    <i className="border-bottom " style={{ fontSize: "12px" }}>"Introducing a sustainable creative platform for true artist&#39;s talent."</i>
                                </div>
                            </div> */}
                            <div className={`${styles["login-signup-box"]} row `}>
                                <div className={`${styles["img-section"]} ${styles["slogan-section"]} col-md-6 col-sm-12 text-center`}>
                                    <div className="logo-section">
                                        <Link to="/">
                                            <figure className={`${styles.logo} mb-0`}>
                                                <img alt="" src="/public/images/resources/logo.png" />
                                                <span>Reverie</span>
                                            </figure>
                                        </Link>
                                    </div>
                                    <div className="center-ball-separator-hr"></div>
                                    <div className={`${styles["slogan"]}`}>
                                        <h3>Introducing a sustainable creative platform for raw talent.</h3>
                                    </div>
                                    <div className="center-ball-separator-hr"></div>

                                    <img className={`${styles["form-bg-img"]}`} ref={loginImg} src="/public/images/resources/sports-login.png" alt="img" />
                                </div>
                                <div className={`${styles["both-form-section"]} col-md-6 col-sm-12`}>
                                    {showSignup && (
                                        <form ref={signupFormRef} className={`form ${styles.form} ${styles["form--register"]}`} onSubmit={onRegister}>
                                            <>
                                                <span>
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width={24}
                                                        height={24}
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        stroke="currentColor"
                                                        strokeWidth={2}
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        className="feather feather-key"
                                                    >
                                                        <path d="M21 2l-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0l3 3L22 7l-3-3m-3.5 3.5L19 4" />
                                                    </svg>
                                                </span>
                                                <h1 className={`${styles.form__title}`}> Sign up</h1>
                                                <div className={`${styles.form__helper}`}>
                                                    <input ref={userName} type="text" name="user" id="new-user" placeholder="Username" className={`${styles.form__input}`} />
                                                    <label className={`${styles.form__label}`} htmlFor="new-user">
                                                        Username
                                                    </label>
                                                </div>
                                                {errors["username"] && <Error error={errors["username"]} />}
                                                <div className={`${styles.form__helper}`}>
                                                    <input ref={signUpEmail} type="email" name="email" id="email" placeholder="Email" className={`${styles.form__input}`} />
                                                    <label className={`${styles.form__label}`} htmlFor="email">
                                                        Email
                                                    </label>
                                                </div>
                                                {errors["email"] && <Error error={errors["email"]} />}
                                                <div className={`${styles.form__helper}`}>
                                                    <i
                                                        className={`${showSignupPassword ? "icofont-eye" : "icofont-eye-blocked"}`}
                                                        style={{ position: "absolute", cursor: "pointer", fontSize: "20px", right: "0px", zIndex: "999" }}
                                                        onClick={() => setShowSignupPassword(!showSignupPassword)}
                                                    ></i>
                                                    <input ref={signUpPassword} type={`${showSignupPassword ? "text" : "password"}`} name="password" id="new-user-password" placeholder="Password" className={`${styles.form__input}`} />
                                                    <label className={`${styles.form__label}`} htmlFor="new-user-password">
                                                        Password
                                                    </label>
                                                </div>
                                                {errors["password"] && <Error error={errors["password"]} />}
                                                <div className={`${styles.form__helper}`}>
                                                    <i
                                                        className={`${showConfirmPassword ? "icofont-eye" : "icofont-eye-blocked"}`}
                                                        style={{ position: "absolute", cursor: "pointer", fontSize: "20px", right: "0px", zIndex: "999" }}
                                                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                                    ></i>
                                                    <input
                                                        ref={confirmPassword}
                                                        type={`${showConfirmPassword ? "text" : "password"}`}
                                                        name="confirm_password"
                                                        id="confirm-user-password"
                                                        placeholder="Confirm Password"
                                                        className={`${styles.form__input}`}
                                                    />
                                                    <label className={`${styles.form__label}`} htmlFor="confirm-user-password">
                                                        Confirm Password
                                                    </label>
                                                </div>
                                                {errors["confirm_password"] && <Error error={errors["confirm_password"]} />}

                                                <div className="d-flex">
                                                    <label className="" htmlFor="">Signup as</label>
                                                    <a id="register-as-tooltip" className="mx-2">
                                                        <i className="icofont-question-circle"></i>
                                                    </a>
                                                    <ReactTooltip anchorId={"register-as-tooltip"} place="top" variant="light" className={`border ${styles.opaque}`} float={true} clickable={true} style={{
                                                        opacity: "1 !important",
                                                        zIndex: "1000",
                                                        width: "fit-content !important"
                                                    }}>

                                                            <div>
                                                                <strong>Individual:</strong> For general users.
                                                            </div>
                                                            <div>
                                                                <strong>Organization:</strong> For talent scouts/companies.
                                                            </div>
                                                        {/* <ul className="my-0">
                                                        </ul> */}
                                                    </ReactTooltip>
                                                </div>
                                                <div className="d-flex mb-4">
                                                    <Input
                                                        name="nature"
                                                        value="user"
                                                        checked={selectedUserType == "user"}
                                                        onChange={() => {
                                                            selectUserType("user");
                                                        }}
                                                        styleType="radio-input"
                                                        placeholder="Individual"
                                                        id="user"
                                                        htmlFor="user"
                                                        className="mr-1"
                                                    />
                                                    <Input
                                                        name="nature"
                                                        value="hunter"
                                                        checked={selectedUserType == "hunter"}
                                                        onChange={() => {
                                                            selectUserType("hunter");
                                                        }}
                                                        styleType="radio-input"
                                                        placeholder="Organization"
                                                        id="hunter"
                                                        htmlFor="hunter"
                                                        className="mr-1"
                                                    />

                                                </div>

                                                <div className="d-flex align-items-center mb-3">
                                                    <input ref={isAgreed} className="d-inline-block mr-3" type="checkbox" id="is_agreed" name="is_agreed" />
                                                    <label className="d-inline-block mb-0" htmlFor="is_agreed" style={{ lineHeight: "14px" }}>
                                                        <small>I accept Reverie's <a href="/terms-and-condition" target="_blank">Terms of Use</a> and <a href="/privacy-policy" target="_blank">Privacy Policy</a> by creating an account</small>
                                                    </label>
                                                </div>
                                                {errors["is_agreed"] && <Error error={errors["is_agreed"]} />}

                                                <Button htmlType="submit" type="button" ref={registerBtn} className={`btn-block mt-2 border-0 text-center`}>
                                                    Sign Up
                                                </Button>
                                                {/* <div className="or-separator">OR</div>
                                                <Button type="button" onClick={onGoogleLogin} className={`${styles["loginBtn"]} ${styles["loginBtn--google"]} btn-block mt-2 text-center`}>
                                                    {" "}
                                                    Sign up with Google{" "}
                                                </Button>
                                                <Button type="button" onClick={onFacebookLogin} className={`${styles["loginBtn"]} ${styles["loginBtn--facebook"]} btn-block mt-2 text-center`}>
                                                    {" "}
                                                    Sign up with Facebook{" "}
                                                </Button> */}
                                                <p className={`${styles.form__text}`}>
                                                    Already have an account?
                                                    <label htmlFor="toggle" className={`${styles.form__link} ml-1`} onClick={toggleForm}>
                                                        Login!
                                                    </label>
                                                </p>
                                            </>
                                        </form>
                                    )}
                                    {!showSignup && (
                                        <form className={`form ${styles.form} ${styles["form--login"]}`} onSubmit={onLogin}>
                                            <span>
                                                <svg
                                                    id="login"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={20}
                                                    height={20}
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    strokeWidth={2}
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    className="feather feather-users"
                                                >
                                                    <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2" />
                                                    <circle cx={9} cy={7} r={4} />
                                                    <path d="M23 21v-2a4 4 0 0 0-3-3.87" />
                                                    <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                                                </svg>
                                            </span>
                                            <h1 className={`${styles.form__title}`}>Login</h1>
                                            <div className={`${styles.form__helper}`}>
                                                <input ref={emailRef} type="text" name="user" id="user" placeholder="Email" className={`${styles.form__input}`} autoFocus />
                                                <label className={`${styles.form__label}`} htmlFor="user">
                                                    Email
                                                </label>
                                            </div>
                                            {errors["email"] && <Error error={errors["email"]} />}
                                            <div className={`${styles.form__helper}`}>
                                                <i
                                                    className={`${showLoginPassword ? "icofont-eye" : "icofont-eye-blocked"}`}
                                                    style={{ position: "absolute", cursor: "pointer", fontSize: "20px", right: "0px", zIndex: "999" }}
                                                    onClick={() => setShowLoginPassword(!showLoginPassword)}
                                                ></i>
                                                <input ref={passwordRef} type={`${showLoginPassword ? "text" : "password"}`} name="password" id="password" placeholder="Password" className={`${styles.form__input}`} />
                                                <label className={`${styles.form__label}`} htmlFor="password">
                                                    Password
                                                </label>
                                            </div>
                                            {errors["password"] && <Error error={errors["password"]} />}
                                            <Button htmlType="submit" type="button" ref={loginBtn} className={`btn-block`}>
                                                Login
                                            </Button>
                                            <p className={`${styles.form__text} my-2 text-right`}>
                                                <label htmlFor="toggle" className={`${styles.form__link} ml-1`} onClick={toggleResetPasswordModal}>
                                                    Forgot Password?
                                                </label>
                                            </p>

                                            <div className="or-separator">OR</div>
                                            <Button type="button" onClick={onGoogleLogin} className={`${styles["loginBtn"]} ${styles["loginBtn--google"]} btn-block mt-2 text-center`}>
                                                {" "}
                                                Login with Google{" "}
                                            </Button>
                                            {/* <Button type="button" onClick={onFacebookLogin} className={`${styles["loginBtn"]} ${styles["loginBtn--facebook"]} btn-block mt-2 text-center`}>
                                                {" "}
                                                Login with Facebook{" "}
                                            </Button> */}
                                            {/* <p className={`${styles.form__text} my-2`}>
                                                <label htmlFor="toggle" className={`${styles.form__link} ml-1`} onClick={toggleResetPasswordModal}>
                                                    Forgot Password?
                                                </label>
                                            </p> */}
                                            <p className={`${styles.form__text} my-2`}>
                                                Don't have an account?
                                                <label htmlFor="toggle" className={`${styles.form__link} ml-1`} onClick={toggleForm}>
                                                    Sign up!
                                                </label>
                                            </p>
                                        </form>
                                    )}
                                </div>
                            </div>
                            {/* <div className={`${styles.login_box}`}>
                                <img ref={loginImg} src="/public/images/resources/sports-login.png" alt="img" className={`${styles.box__image}`} />
                                {showSignup && (
                                    <form ref={signupFormRef} className={`form ${styles.form} ${styles["form--register"]}`} onSubmit={onRegister}>
                                        <>
                                            <span>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={24}
                                                    height={24}
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    strokeWidth={2}
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    className="feather feather-key"
                                                >
                                                    <path d="M21 2l-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0l3 3L22 7l-3-3m-3.5 3.5L19 4" />
                                                </svg>
                                            </span>
                                            <h1 className={`${styles.form__title}`}> Sign up</h1>
                                            <div className={`${styles.form__helper}`}>
                                                <input ref={userName} type="text" name="user" id="new-user" placeholder="Username" className={`${styles.form__input}`} />
                                                <label className={`${styles.form__label}`} htmlFor="new-user">
                                                    Username
                                                </label>
                                            </div>
                                            {errors["username"] && <Error error={errors["username"]} />}
                                            <div className={`${styles.form__helper}`}>
                                                <input ref={signUpEmail} type="email" name="email" id="email" placeholder="Email" className={`${styles.form__input}`} />
                                                <label className={`${styles.form__label}`} htmlFor="email">
                                                    Email
                                                </label>
                                            </div>
                                            {errors["email"] && <Error error={errors["email"]} />}
                                            <div className={`${styles.form__helper}`}>
                                                <i
                                                    className={`${showSignupPassword ? "icofont-eye" : "icofont-eye-blocked"}`}
                                                    style={{ position: "absolute", cursor: "pointer", fontSize: "20px", right: "0px", zIndex: "999" }}
                                                    onClick={() => setShowSignupPassword(!showSignupPassword)}
                                                ></i>
                                                <input
                                                    ref={signUpPassword}
                                                    type={`${showSignupPassword ? "text" : "password"}`}
                                                    name="password"
                                                    id="new-user-password"
                                                    placeholder="Password"
                                                    className={`${styles.form__input}`}
                                                />
                                                <label className={`${styles.form__label}`} htmlFor="new-user-password">
                                                    Password
                                                </label>

                                            </div>
                                            {errors["password"] && <Error error={errors["password"]} />}
                                            <div className={`${styles.form__helper}`}>
                                                <i
                                                    className={`${showConfirmPassword ? "icofont-eye" : "icofont-eye-blocked"}`}
                                                    style={{ position: "absolute", cursor: "pointer", fontSize: "20px", right: "0px", zIndex: "999" }}
                                                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                                ></i>
                                                <input
                                                    ref={confirmPassword}
                                                    type={`${showConfirmPassword ? "text" : "password"}`}
                                                    name="confirm_password"
                                                    id="confirm-user-password"
                                                    placeholder="Confirm Password"
                                                    className={`${styles.form__input}`}
                                                />
                                                <label className={`${styles.form__label}`} htmlFor="confirm-user-password">
                                                    Confirm Password
                                                </label>

                                            </div>
                                            {errors["confirm_password"] && <Error error={errors["confirm_password"]} />}

                                            <div className="d-flex align-items-center mb-3">
                                                <input ref={isAgreed} className="d-inline-block mr-3" type="checkbox" id="is_agreed" name="is_agreed" />
                                                <label className="d-inline-block mb-0" htmlFor="is_agreed" style={{ lineHeight: "14px" }} >
                                                    <small>
                                                        I accept Reverie's Terms of Use and Privacy Policy by creating an account
                                                    </small>
                                                </label>
                                            </div>
                                            {errors["is_agreed"] && <Error error={errors["is_agreed"]} />}

                                            <Button
                                                htmlType="submit"
                                                type="button"
                                                ref={registerBtn}
                                                className={`btn-block mt-2 border-0 text-center`}

                                            >
                                                Sign Up
                                            </Button>
                                            <div className="or-separator">OR</div>
                                            <Button type="button" onClick={onGoogleLogin} className={`${styles["loginBtn"]} ${styles["loginBtn--google"]} btn-block mt-2 text-center`}>
                                                {" "}
                                                Sign up with Google{" "}
                                            </Button>
                                            <Button type="button" onClick={onFacebookLogin} className={`${styles["loginBtn"]} ${styles["loginBtn--facebook"]} btn-block mt-2 text-center`}>
                                                {" "}
                                                Sign up with Facebook{" "}
                                            </Button>
                                            <p className={`${styles.form__text}`}>
                                                Already have an account?
                                                <label htmlFor="toggle" className={`${styles.form__link} ml-1`} onClick={toggleForm}>
                                                    Login!
                                                </label>
                                            </p>
                                        </>
                                    </form>
                                )}
                                {!showSignup && (
                                    <form className={`form ${styles.form} ${styles["form--login"]}`} onSubmit={onLogin}>
                                        <span>
                                            <svg
                                                id="login"
                                                xmlns="http://www.w3.org/2000/svg"
                                                width={20}
                                                height={20}
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                strokeWidth={2}
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                className="feather feather-users"
                                            >
                                                <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2" />
                                                <circle cx={9} cy={7} r={4} />
                                                <path d="M23 21v-2a4 4 0 0 0-3-3.87" />
                                                <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                                            </svg>
                                        </span>
                                        <h1 className={`${styles.form__title}`}>Login</h1>
                                        <div className={`${styles.form__helper}`}>
                                            <input ref={emailRef} type="text" name="user" id="user" placeholder="Email" className={`${styles.form__input}`} autoFocus />
                                            <label className={`${styles.form__label}`} htmlFor="user">
                                                Email
                                            </label>
                                        </div>
                                        {errors["email"] && <Error error={errors["email"]} />}
                                        <div className={`${styles.form__helper}`}>
                                            <i
                                                className={`${showLoginPassword ? "icofont-eye" : "icofont-eye-blocked"}`}
                                                style={{ position: "absolute", cursor: "pointer", fontSize: "20px", right: "0px", zIndex: "999" }}
                                                onClick={() => setShowLoginPassword(!showLoginPassword)}
                                            ></i>
                                            <input
                                                ref={passwordRef}
                                                type={`${showLoginPassword ? "text" : "password"}`}
                                                name="password"
                                                id="password"
                                                placeholder="Password"
                                                className={`${styles.form__input}`}
                                            />
                                            <label className={`${styles.form__label}`} htmlFor="password">
                                                Password
                                            </label>
                                        </div>
                                        {errors["password"] && <Error error={errors["password"]} />}
                                        <Button htmlType="submit" type="button" ref={loginBtn} className={`btn-block`}>


                                            Login
                                        </Button>

                                        <div className="or-separator">OR</div>
                                        <Button type="button" onClick={onGoogleLogin} className={`${styles["loginBtn"]} ${styles["loginBtn--google"]} btn-block mt-2 text-center`}>
                                            {" "}
                                            Login with Google{" "}
                                        </Button>
                                        <Button type="button" onClick={onFacebookLogin} className={`${styles["loginBtn"]} ${styles["loginBtn--facebook"]} btn-block mt-2 text-center`}>
                                            {" "}
                                            Login with Facebook{" "}
                                        </Button>
                                        <p className={`${styles.form__text} my-2`}>
                                            <label htmlFor="toggle" className={`${styles.form__link} ml-1`} onClick={toggleResetPasswordModal}>
                                                Forgot Password?
                                            </label>
                                        </p>
                                        <p className={`${styles.form__text} my-2`}>
                                            Don't have an account?
                                            <label htmlFor="toggle" className={`${styles.form__link} ml-1`} onClick={toggleForm}>
                                                Sign up!
                                            </label>
                                        </p>

                                    </form>
                                )}
                            </div> */}
                        </div>
                    </div>
                    <div className="text-center">
                        <p className="text-dark"><strong>Get the app.</strong></p>
                        <div className="d-flex flex-row justify-content-center mb-sm-3">
                            <a href="https://play.google.com/store/apps/details?id=com.datanet.reverie" target="_blank" rel="noreferrer">
                                <img className="mx-1" src={playstore} alt="download with play store" style={{
                                    maxHeight: "40px"
                                }} />
                            </a>
                            <a href="https://apps.apple.com/pk/app/reverie-global/id1661793078" target="_blank" rel="noreferrer">
                                <img className="mx-1" src={appstore} alt="download with app store" />
                            </a>
                        </div>
                    </div>
                </div>
                

            </ThemeLayout>
        </div>
    );
});

const registerFormSchema = Joi.object({
    username: Joi.string()
        .required()
        .pattern(usernameRegex)
        .messages({
            "string.empty": validateRequired("Username"),
        })
        .messages(usernameValidationSet),

    password: Joi.string()
        .required()
        .pattern(passwordRegex)
        .messages({
            "string.empty": validateRequired("Password"),
        })
        .messages(passwordValidationSet),

    confirm_password: Joi.string()
        .required()
        .messages({
            "string.empty": validateRequired("Confirm Password"),
        }),

    // confirm_password: Joi.string().valid(Joi.ref('password')).messages({
    //     'any.only': 'Password and Confirm Password are not same',
    //     'string.empty': 'Password and Confirm Password are not same'
    // }),

    // confirm_password: Joi.any().equal(Joi.ref('password')).required().options({ messages: { 'any.only': 'Confirm Password does not match'} }),

    email: Joi.string()
        .email({ minDomainSegments: 2, tlds: false })
        .messages({
            "string.empty": validateRequired("Email"),
            "string.email": "A valid email is required",
        }),
});

const loginFormSchema = Joi.object({
    email: Joi.string()
        .email({ minDomainSegments: 2, tlds: false })
        .required()
        .messages({
            "string.empty": validateRequired("Email"),
            "string.email": "A valid email is required",
        }),
    password: Joi.string()
        .required()
        .pattern(passwordRegex)
        .messages({
            "string.empty": validateRequired("Password"),
        })
        .messages(passwordValidationSet),
});

const resetFormSchema = Joi.object({
    email: Joi.string()
        .email({ minDomainSegments: 2, tlds: false })
        .required()
        .messages({
            "string.empty": validateRequired("Email"),
            "string.email": "A valid email is required",
        }),
});

export default Login;
