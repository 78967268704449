import { REPORT_POST } from "../constants/report";

const initState = {
    post: undefined,
};

export default (state = initState, action) => {
    switch (action.type) {
        case REPORT_POST:
            return {
                ...state,
                post: action.payload,
            };
        default:
            return state;
    }
};
