import React, { useImperativeHandle, useMemo, useState } from "react";
import types from "react-day-picker";
import styles from './button.module.css';

const Button = React.forwardRef((props, ref) => {

    const { type, className, children, disabled, htmlType, ...btnProps } = props;

    const [loader, showLoader] = useState(false);

    useImperativeHandle(ref, () => {
        return {
            showLoader,
        }
    }, []);

    const classNameCalc = useMemo(() => {
        let t = '';

        switch (type) {
            case "button":
                t = "btn btn-info";
                break;

            case "link":
                t = `${t} ${styles['link-type-button']}`
                break;
            case "simple":
                t = t + " btn"
                break;
            default:
                t = t + " btn btn-info"
        }

        return `${t} ${className || ""}`.trim();
    }, [className, type])

    return (
        <button {...btnProps} disabled={disabled || loader} type={htmlType} className={classNameCalc} onClick={props.onClick} style={{ ...props.style }}>
            {loader ? (
                <div style={{ margin: "0 0.5rem" }} className="spinner-border spinner-border-sm d-inline-block" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            ) : null}
            {children}
        </button>
    )
});

Button.defaultProps = {
    className: "",
    children: "",
    type: "link",
    htmlType: "button",
    onClick: () => { },
    style: {},
}

export default Button;