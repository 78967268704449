import React, { useImperativeHandle, useRef } from "react";
import "./input.style.css";
import Button from "../Button/Button";
import Icon from "../Icon/Icon";

const Input = React.forwardRef((props, ref) => {
    const { styleType, type, placeholder, prependText, disabled, readOnly, ...inputProps } = props;
    const inputRef = useRef();

    useImperativeHandle(
        ref,
        () =>
            Object.assign(inputRef.current, {
                showDatePicker: () => { },
            }),
        [inputRef],
    );

    const onFocus = async (e) => {
        if (props.onFocus) props.onFocus(e, inputRef.current);
    };

    switch (styleType) {
        case "floating":
            return (
                <label className="pure-material-textfield-outlined">
                    <input disabled={disabled} readOnly={readOnly} {...inputProps} onFocus={onFocus} placeholder=" " ref={inputRef} type={type} className={`${props.className}`} />
                    <span>{props.placeholder}</span>
                    <small className="text-info">{prependText}</small>
                </label>
            );

        case "masked-floating":
            return (
                <label className="pure-material-textfield-outlined">
                    <input {...inputProps} placeholder=" " ref={inputRef} type={type} className={`${props.className}`} />
                    <span>{props.placeholder}</span>
                    <small className="text-info">{prependText}</small>
                </label>
            );

        case "floating-bottom-badge":
            return (
                <>
                    <label className="pure-material-textfield-outlined">
                        <input disabled={disabled} readOnly={readOnly} {...inputProps} onFocus={onFocus} placeholder=" " ref={inputRef} type={type} className={`${props.className}`} />
                        <span>{props.placeholder}</span>
                        <div className="action-btn text-right d-flex justify-content-end mt-1" style={{ gap: "5px" }} >
                            {props.buttons?.map((ab, i) => (
                                <Button key={i} className="btn-sm cursor-pointer" type={`button`} onClick={ab.onActionClick}>{ab.btnText} <Icon name={`long-arrow-right`} /></Button>
                            ))}
                        </div>
                    </label>
                </>


            );

        case "floating-area":
            return (
                <label className="pure-material-textfield-outlined">
                    <textarea {...inputProps} placeholder=" " ref={inputRef} type={type} className={`${props.className}`} />
                    <span>{props.placeholder}</span>
                </label>
            );

        case "switch-checkbox":
            return (
                <div className="custom-control custom-switch cursor-pointer">
                    <input disabled={disabled} readOnly={readOnly} {...inputProps} ref={inputRef} type="checkbox" className="custom-control-input" id={props.id} onChange={props.onChange} />
                    <label className="custom-control-label cursor-pointer" htmlFor={props.id}>
                        {props.placeholder}
                    </label>
                </div>
            );
        case "radio-input":
            return (
                <div className="form-check d-inline mr-3">
                    <label className={`d-flex align-items-center mb-0`} htmlFor={props.htmlFor}>
                        <input disabled={disabled} readOnly={readOnly} {...inputProps} className={props.className} type="radio" name={props.name} id={props.id} value={props.value} />
                        <span className="labelname">{props.placeholder}</span>
                    </label>
                </div>
            );

        default:
            return <input disabled={disabled} readOnly={readOnly} {...inputProps} placeholder={placeholder} ref={inputRef} type={type} className={`${props.className}`} />;
    }
});

Input.defaultProps = {
    className: "",
    type: "text",
    placeholder: "",
    disabled: false,
    readOnly: false,
};

export default Input;
