import moment from "moment";
import "moment/locale/en-gb";

export const fromNow = (date) => {
    moment.locale("en-gb");
    return moment.utc(date).local().fromNow();
};

export const daysRemaining = (date) => {
    return `Ends ${getDate(date).add(1, "days").local().fromNow()}`;
};

export const getDate = (date) => {
    return moment(date, "YYYY-MM-DD");
};

export const formattedDate = (date) => {
    return getDate(date).format("MM/DD/YYYY");
};

export const getCalendarDate = (date) => {
    return moment(date).calendar({
        sameDay: "[Today]",
        nextDay: "[Tomorrow]",
        nextWeek: "dddd",
        lastDay: "[Yesterday]",
        lastWeek: "dddd",
        sameElse: "DD/MM/YYYY",
    });
};
