import { apiCall } from "../helpers/api";
import { GoogleAuthProvider, signInWithPopup, getAuth } from "firebase/auth";
import { setCurrentUser } from "../redux/actions/user";
import { setProvider, setReverieParams, setStripeClientId } from "../redux/actions/app";
import { toast } from "react-toastify";

export const userLogin =
    (provider = "api", params = {}) =>
    (dispatch) => {
        switch (provider) {
            case "google":
                return signInWithPopup(getAuth, new GoogleAuthProvider());

            case "facebook":
                return () => "facebook";

            case "github":
                return () => "github";

            default:
                return dispatch(login(params));
        }
    };

export const users =
    (params = {}) =>
    (dispatch) => {
        params.path = params.path || "users";
        // params.method = params.method || "GET";

        return dispatch(apiCall(params));
    };

export const login =
    (params = {}) =>
    (dispatch) => {
        params.method = params.method || "POST";
        params.path = params.path || "login";
        return dispatch(apiCall(params, onSuccessLogin));
    };

export const onSuccessLogin = (response, params) => (dispatch) => {
    if (response.success) {
        localStorage.setItem("user_token", response.data.token);
        // localStorage.setItem("user", JSON.stringify(response.data.user));
        dispatch(setCurrentUser(response.data.user));
        dispatch(setStripeClientId(response.data.stripe_client_id));
        dispatch(setReverieParams(response.data.reverie_params));
        dispatch(setProvider(response.data.provider));
    }
};

/*------------------------------------------ Register ----------------------------------------------------*/

export const registerUser =
    (params = {}) =>
    (dispatch) => {
        params.method = params.method || "POST";
        params.path = params.path || "users";
        return dispatch(apiCall(params, onSuccessRegisterUser));
    };

const onSuccessRegisterUser = (response, params) => (dispatch) => {

    if (response.success) {
        toast.success(response.message);
        // localStorage.setItem("user_token", response.data.token);
        // localStorage.setItem("user", JSON.stringify(response.data.user));
        // dispatch(setCurrentUser(response.data.user));
        // toast.success("Welcome to Reverie");
    }
};

export const logoutFromApp =
    (params = {}) =>
    (dispatch) => {
        params.method = params.method || "POST";
        params.path = params.path || "logout";
        return dispatch(apiCall(params));
    };

export const socialLogin =
    (params = {}) =>
    (dispatch) => {
        params.method = params.method || "POST";
        params.path = params.path || "social-login";
        return dispatch(apiCall(params));
    };

export const updateUsername =
    (params = {}) =>
    (dispatch) => {
        params.method = params.method || "POST";
        params.path = params.path || "user-name-update";
        return dispatch(apiCall(params));
    };
