import { io } from "socket.io-client";
let socket = null;

export const init = (user_id = 0) => {
    socket = io(`${window.SOCKET_SERVER_URL}/user-${user_id}`, { transports: ['websocket', 'polling'] });
    return socket;
}

export const getSocket = (user_id = 0) => {
    return !socket ? init(user_id) : socket;
}