import axios from "axios";
import { logoutUser, redirectToLogin } from "../redux/actions/user";
import { setShowLoader } from "../redux/actions/app";

export const apiCall = (params, onSuccess, onFailure) => (dispatch) =>
    new Promise((resolve, reject) => {
        const { RAZZLE_BASE_URL } = window;

        if (params.param_path) {
            params.path += `/${params.param_path}`;
        }

        const requestingObject = {
            url: `${RAZZLE_BASE_URL?.endsWith("/") ? RAZZLE_BASE_URL?.slice(0, -1) : RAZZLE_BASE_URL}/${params.path || ""}`,
            headers: dispatch(getHeaders(params)),
            method: params.method ? params.method : "GET",
            data: params.data || {},
            params: params.params ? params.params : {},
            responseType: params.responseType || "json",
            onUploadProgress: params.onUploadProgress,
        };

        if (params.isBackground == true) {
            dispatch(setShowLoader(true));
        }

        return axios(requestingObject)
            .then((response) => {
                dispatch(setShowLoader(false));
                // OnSuccess common validations
                if (onSuccess) dispatch(onSuccess(response.data, params));
                else console.log("onSuccess " + requestingObject.url, response.data);
                resolve(response.data);
            })
            .catch((err) => {
                dispatch(setShowLoader(false));
                if (onFailure) dispatch(onFailure(err, params));
                else {
                    if (err.response.status === 401) {
                        localStorage.removeItem("user_token");
                        dispatch(logoutUser());
                        // @TODO: useHistory to fix this redirection issue abnormality
                        // window.location = '/login';
                        dispatch(redirectToLogin());
                    }
                }
                reject(err);
            });
    });

const getHeaders =
    (params = {}) =>
    (dispatch, getState) => {
        const a = {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Max-Age": 600,
            ...params?.headers,
        };

        const token = getState().user?.token || localStorage.getItem("user_token");
        if (token) {
            a["Authorization"] = `Bearer ${token}`;
        }

        return a;
    };
