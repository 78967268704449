import React, { Fragment, useEffect, useState } from "react";
import styles from "./styles.module.css";
import RoundImage from "../Image/RoundImage";
import { BANNER, PLACEHOLDER } from "../../helpers/constant";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Autocomplete = (props) => {
    const [suggestions, setSuggestions] = useState([]);
    const inputSuggestions = props.suggestions;
    const history = useHistory();
    const [activeSuggestion, setActiveSuggestion] = useState(0);

    useEffect(() => {
        let s = [];
        let i = 0;
        if (inputSuggestions.users?.length > 0) {
            s.push({
                id: i++,
                type: "heading",
                text: "Users",
            });
            inputSuggestions.users?.map((u) => {
                s.push({
                    id: i++,
                    type: "user",
                    data: u,
                });
            });
        }

        if (inputSuggestions.clubs?.length > 0) {
            s.push({
                id: i++,
                type: "heading",
                text: "Clubs",
            });
            inputSuggestions.clubs?.map((c) => {
                s.push({
                    id: i++,
                    type: "club",
                    data: c,
                });
            });
        }

        if (s.length > 0) {
            s.push({
                id: i++,
                type: "button",
                text: `Search for ${props.query}`,
                src: `/search/${props.query}`,
            });
        }

        setSuggestions(s);
    }, [inputSuggestions]);

    return (
        <Fragment>
            {props.children}
            {suggestions.length > 0 && (
                <ul className={`${styles["suggestions"]} rounded`}>
                    {suggestions.length > 0 &&
                        suggestions.map((s) => {
                            if (s.type === "user") {
                                return (
                                    <li
                                        key={s.id}
                                        className="pl-3 text-capitalize text-truncate"
                                        onClick={() => {
                                            history.push(`/profile/${s.data.id}`);
                                        }}
                                    >
                                        {/* <User user={s.data} /> */}
                                        <Link to={`/profile/${s.data.id}`}>
                                            <RoundImage size="30px" src={s.data.profile_pic || PLACEHOLDER} />
                                            <span className="ml-3">{s.data.name}</span>
                                        </Link>
                                    </li>
                                );
                            } else if (s.type === "club") {
                                return (
                                    <li key={s.id} className="pl-3 text-capitalize text-truncate">
                                        <Link to={`/clubs/${s.data.id}`}>
                                            <RoundImage size="30px" src={s.data.avatar || BANNER} />
                                            <span className="ml-3">{s.data.title}</span>
                                        </Link>
                                    </li>
                                );
                            } else if (s.type === "heading") {
                                return (
                                    <li key={s.id}>
                                        <span className="h6">{s.text}</span>
                                    </li>
                                );
                            } else if (s.type === "button") {
                                return (
                                    <li key={s.id} className="text-center">
                                        <Link to={s.src}>{s.text}</Link>
                                    </li>
                                );
                            }
                        })}
                    {/* {suggestions?.map((suggestion, index) => {
                        let className;

                        // Flag the active suggestion with a class
                        if (index === activeSuggestion) {
                            className = "suggestion-active";
                        }

                        return (
                            <li className={styles[className]} key={suggestion}>
                                {suggestion}
                            </li>
                        );
                    })} */}
                </ul>
            )}
        </Fragment>
    );
};

export default Autocomplete;
// class Autocomplete extends Component {
//     static propTypes = {
//         suggestions: PropTypes.instanceOf(Array),
//     };

//     static defaultProps = {
//         suggestions: [],
//     };

//     constructor(props) {
//         super(props);

//         this.state = {
//             // The active selection's index
//             activeSuggestion: 0,
//             // The suggestions that match the user's input
//             filteredSuggestions: [],
//             // Whether or not the suggestion list is shown
//             showSuggestions: false,
//             // What the user has entered
//             userInput: "",
//         };
//     }

//     onChange = (e) => {
//         const { suggestions } = this.props;
//         const userInput = e.currentTarget.value;

//         // Filter our suggestions that don't contain the user's input
//         const filteredSuggestions = suggestions.filter((suggestion) => suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1);

//         this.setState({
//             activeSuggestion: 0,
//             filteredSuggestions,
//             showSuggestions: true,
//             userInput: e.currentTarget.value,
//         });
//     };

//     onClick = (e) => {
//         this.setState({
//             activeSuggestion: 0,
//             filteredSuggestions: [],
//             showSuggestions: false,
//             userInput: e.currentTarget.innerText,
//         });
//     };

//     onKeyDown = (e) => {
//         const { activeSuggestion, filteredSuggestions } = this.state;

//         // User pressed the enter key
//         if (e.keyCode === 13) {
//             this.setState({
//                 activeSuggestion: 0,
//                 showSuggestions: false,
//                 userInput: filteredSuggestions[activeSuggestion],
//             });
//         }
//         // User pressed the up arrow
//         else if (e.keyCode === 38) {
//             if (activeSuggestion === 0) {
//                 return;
//             }

//             this.setState({ activeSuggestion: activeSuggestion - 1 });
//         }
//         // User pressed the down arrow
//         else if (e.keyCode === 40) {
//             if (activeSuggestion - 1 === filteredSuggestions.length) {
//                 return;
//             }

//             this.setState({ activeSuggestion: activeSuggestion + 1 });
//         }
//     };

//     render() {
//         const {
//             onChange,
//             onClick,
//             onKeyDown,
//             state: { activeSuggestion, filteredSuggestions, showSuggestions, userInput },
//         } = this;

//         let suggestionsListComponent;

//         if (showSuggestions && userInput) {
//             if (filteredSuggestions.length) {
//                 suggestionsListComponent = (
//                     <ul class="suggestions">
//                         {filteredSuggestions.map((suggestion, index) => {
//                             let className;

//                             // Flag the active suggestion with a class
//                             if (index === activeSuggestion) {
//                                 className = "suggestion-active";
//                             }

//                             return (
//                                 <li className={className} key={suggestion} onClick={onClick}>
//                                     {suggestion}
//                                 </li>
//                             );
//                         })}
//                     </ul>
//                 );
//             } else {
//                 suggestionsListComponent = (
//                     <div class="no-suggestions">
//                         <em>No suggestions, you're on your own!</em>
//                     </div>
//                 );
//             }
//         }

//         return (
//             <Fragment>
//                 <input type="text" onChange={onChange} onKeyDown={onKeyDown} value={userInput} />
//                 {suggestionsListComponent}
//             </Fragment>
//         );
//     }
// }
