import React from 'react';
import { hydrate } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { configureStore } from './redux';
import { Provider } from 'react-redux';
import { loadableReady } from '@loadable/component';

let preloadedState = {};

try {
  preloadedState = JSON.parse(window.__PRELOADED_STATE__)
} catch (e) {
  console.log(e);
}

const store = configureStore(preloadedState);

loadableReady().then(() => {
  hydrate(
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>,
    document.getElementById('root')
  )
});

if (module.hot) {
  module.hot.accept();
}
