export const passwordValidationSet = {
    // uses regex. based on Joi -> https://github.com/sideway/joi/blob/master/API.md#list-of-errors
    "string.pattern.base": "Your password must contain at least 8 characters, including a number, letter one uppercase without space.",
};

export const usernameValidationSet = {
    // uses regex. based on Joi -> https://github.com/sideway/joi/blob/master/API.md#list-of-errors
    "string.pattern.base": "Username must have atleast 5 characters and could contain alphabets and underscore(_)",
};

export const validateRequired = (name) => {
    return `${name} is required`;
};

export const setErrorMessages = (messagesArray, server = false) => {
    if (server) {
        const errors = messagesArray.response?.data?.data?.message || messagesArray.response?.data?.errors || {};

        return Object.keys(errors).reduce((result, key) => {
            result[`${key || ""}`] = errors[key][0];
            return result;
        }, {});
    } else {
        return messagesArray.details.reduce((result, value, index) => {
            result[`${value.context.key || index}`] = value.message;
            return result;
        }, {});
    }
};
