import React, { useRef, useState, useMemo, useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { messages as messagesApi } from "../../api/messages";
import { getSocket } from "../../core/socket";
import { getCalendarDate } from "../../helpers/dates";
import { closeUserChat, setUserChat } from "../../redux/actions/messages";
import Button from "../Button/Button";
import Icon from "../Icon/Icon";
import _ from "lodash";
import { PLACEHOLDER } from "../../helpers/constant";
// import InfiniteList from "../List/List";
import "./chatbox.style.css";
import moment from "moment";
import RoundImage from "../Image/RoundImage";
import ScrollList from "../ScrollList/ScrollList";

const ChatBox = React.memo((props) => {
    const [collapse, setCollapse] = useState(false);
    const [historyPagination, setHistoryPagination] = useState({});
    const chats = useSelector((store) => store.messages.activeChats?.[props.userIdName] || []);
    const propic = useRef(PLACEHOLDER);

    const [userId, userName] = useRef(props.userIdName?.split("|")).current;
    const user = useSelector((store) => store.user.user);
    const dispatch = useDispatch();

    const formRef = useRef();
    const listRef = useRef();
    const removeCurrentUser = (e) => {
        e.stopPropagation();
        dispatch(closeUserChat(props.userIdName));
    };

    const onSendMessage = useCallback(
        async (e) => {
            e.preventDefault();
            try {
                const formData = new FormData(formRef.current);

                const params = {
                    method: "POST",
                    data: {
                        target_id: userId,
                        message: formData.get("message"),
                    },
                };
                formRef.current?.reset();
                const response = await dispatch(messagesApi(params));
                dispatch(setUserChat(props.userIdName, props.profilePic, [response.data]));
                getSocket().emit("messaging", response.data);
            } catch (err) {
                console.log(err);
            }
            return false;
        },
        [dispatch, props.userIdName, userId],
    );

    const onCollapsing = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setCollapse((c) => !c);
    };

    const getUserHistoryMessages = useCallback(async () => {
        listRef.current?.setLoading(true);
        try {
            const params = {
                param_path: userId,
            };
            if (historyPagination.current_page < historyPagination.last_page) {
                params.params = {
                    load: chats[chats.length - 1]?.id,
                };
            }
            const response = await dispatch(messagesApi(params));
            dispatch(setUserChat(props.userIdName, props.profilePic, response.data));

            if (response.data.length > 0) {
                if (userId == response.data[0].source_user.id) {
                    propic.current = response.data[0].source_user.profile_pic ?? PLACEHOLDER;
                } else {
                    propic.current = response.data[0].target_user.profile_pic ?? PLACEHOLDER;
                }
            }

            setHistoryPagination(response.pagination_data);
            listRef.current?.setLoading(false);
        } catch (err) {
            console.log(err);
        }
    }, [dispatch, userId, historyPagination, chats, props.userIdName]);

    useEffect(() => {
        getUserHistoryMessages();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderItems = useCallback(
        ({ item }) => {
            return (
                <li key={item.id} className={`${parseInt(item?.target_user?.id) == parseInt(userId) ? "me" : "you"}`}>
                    <div className="chat-thumb m-1">
                        {/* <img src={item?.source_user?.profile_pic || PLACEHOLDER} alt="" /> */}
                        <RoundImage src={item?.source_user?.profile_pic || PLACEHOLDER} size="35px" />
                    </div>
                    <div className="notification-event">
                        <span className="chat-message-item">{item.message}</span>
                        <span className="notification-date">
                            <time dateTime={item.created_at} className="entry-date updated">
                                {moment(item.created_at).format("hh:mm a")}
                            </time>
                            {/* <i><img src="/public/images/resources/d-tick.png" alt="" /></i> */}
                        </span>
                    </div>
                </li>
            );
        },
        [userId],
    );

    const transformChatByGroup = useMemo(() => {
        return _.chain(chats)
            .groupBy((m) => getCalendarDate(m.created_at))
            .reduce((result, value, key) => {
                result[key] = value;
                return result;
            }, {})
            .value();
    }, [chats]);

    const renderChatItem = useCallback(
        (key) => {
            const chatgroup = transformChatByGroup[key];
            return (
                <React.Fragment key={key}>
                    {chatgroup?.map((item) => renderItems({ item }))}
                    <li>
                        <p>{key}</p>
                    </li>
                </React.Fragment>
            );
        },
        [renderItems, transformChatByGroup],
    );

    const renderOnEmptyChat = useMemo(() => {
        return (
            <div className="chat-empty">
                <p>Say Hi</p>
            </div>
        );
    }, []);

    const onKeyDown = (event) => {
        if (event.keyCode === 13) {
            event.preventDefault();
            onSendMessage(event);
        }
    };

    return (
        <div className={`chat-card ${collapse ? "minimize_chat" : ""}`}>
            <div className="chat-card-head d-flex align-items-center" onClick={onCollapsing}>
                {/* <img src={propic.current} alt="" /> */}
                <RoundImage src={propic.current} alt="" size="35px" />
                {/* <img src={`${user?.profile_pic || PLACEHOLDER}`} alt="" /> */}
                <h6 className="ml-3 text-truncate flex-fill">{userName}</h6>
                <div className="frnd-opt d-flex align-items-center">
                    {!collapse && (
                        <Button className="minus-message" type="link" onClick={onCollapsing}>
                            <Icon name="minus" />
                        </Button>
                    )}

                    <Button className="close-message" type="link" onClick={removeCurrentUser}>
                        <Icon name="close" />
                    </Button>
                </div>
            </div>
            <ul>
                <ScrollList
                    data={Object.keys(transformChatByGroup)}
                    renderItem={renderChatItem}
                    isReverse
                    onEndReach={getUserHistoryMessages}
                    renderOnEmptyList={renderOnEmptyChat}
                    threshold={100}
                    hasMore={historyPagination.current_page < historyPagination.last_page}
                />
            </ul>
            {

            }
            <form ref={formRef} className="text-box" onSubmit={onSendMessage}>
                <textarea onKeyDown={onKeyDown} name="message" placeholder="Write Mesage..." defaultValue={""} maxLength="200" />

                <button htmlType="submit" type="button" onClick={onSendMessage}>
                    <i className="icofont-paper-plane" />
                </button>
            </form>
        </div>
    );
});

export default ChatBox;
