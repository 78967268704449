import { CLOSE_USER_CHAT, SET_LATEST_MESSAGE, SET_USER_CHAT } from "../constants/messages";

export const setUserChat = (userIdName, profilePic = null, messages = []) => {
    return {
        type: SET_USER_CHAT,
        userIdName,
        profilePic,
        messages,
    };
};

export const closeUserChat = (userIdName) => {
    return {
        type: CLOSE_USER_CHAT,
        userIdName,
    };
};

export const setLatestMessage = (message) => {
    return {
        type: SET_LATEST_MESSAGE,
        message,
    };
};

export const closeAllChats = () => {
    return {
        type: CLOSE_USER_CHAT,
    };
};
