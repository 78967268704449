import React from 'react';
import Footer from 'component/Footer/Footer';
import Header from 'component/Header/Header';

const ThemeLayout = React.memo((props) => {
    return (
        <div className={`theme-layout ${props.className}`.trim()}>
            {props.showHeader ? (<Header />): null}
            {props.children}
            {props.showFooter ? (<Footer />): null}
        </div>
    )
})

ThemeLayout.defaultProps = {
    showHeader: true,
    showFooter: true,
    className: "",
}

export default ThemeLayout;
