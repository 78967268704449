import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import reducers from "./reducers";
import { composeWithDevTools } from 'redux-devtools-extension';

const middlewares = [thunk];
let appliedMiddlewares = applyMiddleware(...middlewares);

if (process?.env?.NODE_ENV && process?.env?.NODE_ENV != "production")
  appliedMiddlewares = composeWithDevTools(applyMiddleware(...middlewares))

let store = createStore(reducers, {}, appliedMiddlewares);

export function configureStore(preloadedState = {}) {
  const store = createStore(reducers, preloadedState, appliedMiddlewares);
  return store;
}

if (module.hot)
  module.hot.accept("./reducers", () => {
    const nextRootReducer = require("./reducers");
    store.replaceReducer(nextRootReducer);
  });


export default store;
