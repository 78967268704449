import React, { useEffect, useCallback } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setCurrentUser } from "./redux/actions/user";
import { ToastContainer } from "react-toastify";
import { useFacebookSDK } from "hooks/useFacebookSDK";
import useSocket from "./hooks/useSocket";
import { initFirebase } from "./core/firebase";
import { useSelector } from "react-redux";
import ChatBox from "./component/ChatBox/ChatBox";
import "react-toastify/dist/ReactToastify.css";
import "react-day-picker/lib/style.css";
import "./App.css";

import loadable from "@loadable/component";
import PageLoader from "./component/PageLoader/PageLoader";
import { users } from "./api/users";

import { setProvider, setReverieParams, setShowLoader, setStripeClientId } from "./redux/actions/app";
import ProtectedComponent from "./pages/ProtectedComponent/ProtectedComponent";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

export var stripePromise = null; //loadStripe(window.STRIPE_KEY);

// pages
const Home = loadable(() => import("./pages/Home/Home"));
const Login = loadable(() => import("./pages/Login/Login"));
const Profile = loadable(() => import("./pages/Profile/Profile"));
const EditProfile = loadable(() => import("./pages/EditProfile/EditProfile"));
const Challenge = loadable(() => import("./pages/Challenge/Detail/Challenge"));
const Hunting = loadable(() => import("./pages/Hunting/Hunting"));
const HuntDetail = loadable(() => import("./pages/Hunting/Detail/HuntDetail"));
const Applicant = loadable(() => import("./pages/Hunting/ApplicantDetail/Applicant"));
const ApplicantResume = loadable(() => import("./pages/Hunting/ApplicantDetail/ApplicantResume"));
const Clubs = loadable(() => import("./pages/Clubs/Clubs"));
const ClubDetail = loadable(() => import("./pages/Clubs/Detail/ClubDetail"));
const CreatePost = loadable(() => import("./pages/Posts/Create/CreatePost"));
const PostDetail = loadable(() => import("./pages/Posts/Detail/PostDetail"));
const BoostPost = loadable(() => import("./pages/Posts/Detail/BoostPost"));
const NewChallenge = loadable(() => import("./pages/NewChallenge/NewChallenge"));
const AcceptChallenge = loadable(() => import("./pages/Challenge/Accept/AcceptChallenge"));
const Challengers = loadable(() => import("./pages/Challenge/Challengers/Challengers"));
const NewClub = loadable(() => import("./pages/NewClub/NewClub"));
const NewHunt = loadable(() => import("./pages/NewHunt/NewHunt"));
const Listing = loadable(() => import("./pages/Listing/Listing"));
const Search = loadable(() => import("./pages/Search/Search"));
const StripeConnect = loadable(() => import("./pages/Stripe/StripeConnect"));
const ChatRoom = loadable(() => import("./pages/Messages/ChatRoom"));
const BoostInsights = loadable(() => import("./pages/Posts/Boost/BoostInsights"));
const PrivacyPolicy = loadable(() => import("./pages/PrivacyPolicy/PrivacyPolicy"));
const TermsAndConditions = loadable(() => import("./pages/TermsAndConditions/TermsAndConditions"));
const HelpAndSupport = loadable(() => import("./pages/HelpAndSupport/HelpAndSupport"));
const NotFound = loadable(() => import("./pages/NotFound/NotFound"));
const ResetPassword = loadable(() => import("./pages/ResetPassword/ResetPassword"));
const VerifyEmail = loadable(() => import("./pages/VerifyEmail/VerifyEmail"));
const ResendEmail = loadable(() => import("./pages/ResendEmail/ResendEmail"));
const FAQs = loadable(() => import("./pages/FAQs/FAQs"));

const ProtectedRoute = ({ component: Component, ...restOfProps }) => {
    const user = useSelector((store) => store.user.user);

    if (user.id != undefined) {
        return <Route {...restOfProps} component={Component} />;
    } else {
        return <ProtectedComponent requested={restOfProps.path} />;
    }
};

const App = React.memo(() => {
    const history = useHistory();
    const dispatch = useDispatch();
    const redirect = useSelector((store) => store.user.needs_login);
    const auth_user = useSelector((store) => store.user.user);

    const { activeChats } = useSelector((store) => {
        return {
            activeChats: store.messages.activeChats,
        };
    });

    useSocket();

    const loadUser = useCallback(async () => {
        try {
            const token = localStorage.getItem("user_token");

            if (token) {
                dispatch(
                    users({
                        param_path: "current",
                    })
                )
                    .then((response) => {
                        if (response.success) {
                            setShowLoader(false);
                            dispatch(setCurrentUser(response.data.user));
                            dispatch(setStripeClientId(response.data.stripe_client_id));
                            dispatch(setReverieParams(response.data.reverie_params));
                            dispatch(setProvider(response.data.provider));
                            stripePromise = loadStripe(window.STRIPE_KEY);
                        }
                    })
                    .catch((e) => {
                        setShowLoader(false);
                        localStorage.setItem("user_token", "");
                    });
            }
        } catch (e) {
            console.log(e);
        }
    }, [dispatch]);

    useFacebookSDK();

    useEffect(() => {
        loadUser();
        initFirebase();
    }, [dispatch, loadUser]);

    useEffect(() => {
        if (redirect) {
            history.replace("/login");
        }
    }, [history, redirect]);

    return (
        <>
            <Elements stripe={stripePromise}>
                <Switch>
                    <Route exact={true} path="/" component={Home} />
                    <Route exact={true} path="/login" component={Login} />
                    <Route exact={true} path="/register" component={Login} />
                    <ProtectedRoute exact={true} path="/profile" component={Profile} />
                    <Route exact={true} path="/profile/:userId" component={Profile} />
                    <ProtectedRoute exact={true} path="/profile/edit/:userId/:tab?" component={EditProfile} />

                    <ProtectedRoute exact={true} path="/create-post" component={CreatePost} />
                    <ProtectedRoute exact={true} path="/post/:post_id/edit" component={CreatePost} />
                    <Route exact={true} path="/post/:post_id" component={PostDetail} />

                    <Route exact={true} path="/boostpost/:post_id" component={BoostPost} />

                    <Route exact={true} path="/challenge" component={Challenge} />
                    <ProtectedRoute exact={true} path="/create-challenge" component={NewChallenge} />
                    <ProtectedRoute exact={true} path="/challenge/:challenge_id/edit" component={NewChallenge} />
                    <ProtectedRoute exact={true} path="/challenge/:challengeId/accept" component={AcceptChallenge} />
                    <Route exact={true} path="/challenges/:challengeId/challengers" component={Challengers} />

                    <ProtectedRoute exact={true} path="/create-hunt" component={NewHunt} />
                    <Route exact={true} path="/hunting" component={Hunting} />
                    <Route exact={true} path="/hunt/:huntId" component={HuntDetail} />
                    <ProtectedRoute exact={true} path="/hunt/:hunt_id/edit" component={NewHunt} />
                    <ProtectedRoute exact={true} path="/hunts/:huntId/cv/:id" component={Applicant} />
                    <ProtectedRoute exact={true} path="/hunts/:huntId/resume/:id" component={ApplicantResume} />
                    <ProtectedRoute exact={true} path="/my-cv/:id" component={Applicant} />
                    <ProtectedRoute exact={true} path="/my-resume/:id" component={ApplicantResume} />

                    <Route exact={true} path="/clubs" component={Clubs} />
                    <ProtectedRoute exact={true} path="/clubs/:clubId" component={ClubDetail} />
                    <ProtectedRoute exact={true} path="/club/:club_id/edit" component={NewClub} />
                    <ProtectedRoute exact={true} path="/create-club" component={NewClub} />

                    <Route exact={true} path="/all/:type/:id" component={Listing} />
                    <Route exact={true} path="/all/:type" component={Listing} />

                    <Route exact={true} path="/notfound" component={NotFound} />
                    <Route exact={true} path="/search/:query?" component={Search} />

                    <Route exact={true} path="/messages" component={ChatRoom} />
                    <ProtectedRoute exact={true} path="/stripe-connect" component={StripeConnect} />
                    <ProtectedRoute exact={true} path="/boosts/:id" component={BoostInsights} />
                    <Route exact={true} path="/password/reset/:token" component={ResetPassword} />

                    <Route path="/privacy-policy" component={PrivacyPolicy} />
                    <Route path="/terms-and-condition" component={TermsAndConditions} />
                    <Route path="/help-and-support" component={HelpAndSupport} />
                    <Route path="/verify/:otp" component={VerifyEmail} />
                    <Route path="/resend/email/" component={ResendEmail} />
                    <Route path="/faqs" component={FAQs} />
                    <Route path="*" component={NotFound} />
                </Switch>
                <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
                {history?.location?.pathname !== "/messages" && auth_user.id && (
                    <div className="row m-0" style={{ bottom: "0", position: "fixed", width: "100%", zIndex: "9999" }}>
                        <div className="chat-boxes-section col-md-10">
                            <div className="messages__root">
                                {/* <ChatUserList /> */}
                                {Object.keys(activeChats).length > 0
                                    ? Object.keys(activeChats).map((userIdName) => {
                                          return <ChatBox key={userIdName} userIdName={userIdName} />;
                                      })
                                    : null}
                            </div>
                        </div>

                        <div className="bottom-chat-options d-none col-md-2"></div>
                    </div>
                )}

                <PageLoader />
            </Elements>
        </>
    );
});

export default App;
