import { combineReducers } from "redux";
import app from "./app";
import user from "./user";
import post from "./post";
import posts from "./posts";
import messages from "./messages";
import notifications from "./notifications";
import report from "./report";

const reducers = combineReducers({
    app,
    user,
    post,
    posts,
    messages,
    notifications,
    report,
});

export default reducers;
