import { useEffect } from "react";
import { getSocket, init } from "../core/socket";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { setLatestMessage, setUserChat } from "../redux/actions/messages";
import { getNotification } from "../redux/actions/notifications";
import { notifications as notificationsApi } from "../api/notifications";
import { useHistory } from "react-router-dom";

const useSocket = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { user } = useSelector((store) => {
        return {
            user: store.user?.user || {},
        };
    });

    useEffect(() => {
        init(user?.id);
    }, [user]);

    useEffect(() => {
        const socket = getSocket(user.id);
        socket.on("messaging", (data) => {
            dispatch(setLatestMessage(data));
            dispatch(setUserChat(`${data?.source_user?.id}|${data?.source_user?.name}`, `${data?.source_user?.profile_pic}`, [data]));
        });
        return () => {
            socket.off("messaging");
        };
    }, [dispatch, user]);

    useEffect(() => {
        const socket = getSocket(user.id);
        socket.on("notify", (data) => {
            // console.log("Received Notification", data);
            // dispatch(addNotification(data));

            dispatch(notificationsApi())
                .then((response) => {
                    if (response.success) {
                        dispatch(getNotification(response.data));
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        });
        return () => {
            socket.off("notify");
        };
    }, [user]);
};
export default useSocket;
