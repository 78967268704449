import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";

const Footer = React.memo(() => {
    return (
        <>
            {/* <figure className="bottom-mockup">
                <img alt="" src="/public/images/resources/footer.png" style={{ opacity: "0" }} />
            </figure> */}
            <div className="bottombar">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <span>&copy; Copyright All rights reserved by Reverie {moment().year()}</span> | <Link to="/privacy-policy">
                                <span>Privacy Policy</span> </Link>| <Link to="/terms-and-condition">
                                <span>Terms & Conditions</span> </Link>| <a href="https://1reverie.com" rel="noreferrer" target="_blank">
                                <span>About Us</span></a> | <a href="/faqs" rel="noreferrer" target="_blank">
                                <span>FAQs</span></a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
});

export default Footer;
