import { useEffect } from "react"

export const useFacebookSDK = () => {
    useEffect(() => {
        window.FB?.init({
            appId: process.env.RAZZLE_FACEBOOK_APP_ID,
            cookie: true,
            xfbml: true,
            version: 'v12.0'
        });
    }, [])
}