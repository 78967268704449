import { ADD_NOTIFICATION, GET_NOTIFICATIONS } from "../constants/notifications";

export default (state=[], action) => {
    let id = 1; 
    let date = (new Date()).toISOString();
    switch (action.type) {
        case GET_NOTIFICATIONS:
            return action.payload;
       
        case ADD_NOTIFICATION:
            if(state.length > 0){
                id = state[0].id+1; 
            }

            return [
                {
                    id: id,
                    created_at: (new Date()).toISOString(),
                    description: action.payload.text,
                    ...action.payload
                },
                ...state
            ];
        
        default:
            return state;
    }
}