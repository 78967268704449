import React from 'react';

const RoundImage = React.memo((props) => {
    
    const onLoad = async () => {

    };

    return (
        <img className={props.className} src={props.src} alt={props.alt || "#"} style={{borderRadius:'100%', width:props.size,height:props.size,maxWidth:props.size }}  onLoad={onLoad} />
    )
});

export default RoundImage;