import { APP, FIREBASE_TOKEN, LOGIN_PROVIDER, REVERIE_PARAMS, SET_PLAYER, SHOW_LOADER, STRIPE_CLIENT_ID } from "../constants/app";

export const setApp = (app) => {
    return {
        type: APP,
        app,
    };
};

export const setFirebaseToken = (firebaseToken) => {
    return {
        type: FIREBASE_TOKEN,
        firebaseToken,
    };
};

export const setShowLoader = (showLoader) => {
    return {
        type: SHOW_LOADER,
        showLoader,
    };
};

export const setStripeClientId = (stripeClientId) => {
    return {
        type: STRIPE_CLIENT_ID,
        stripe_client_id: stripeClientId,
    };
};

export const setReverieParams = (reverieParams) => {
    return {
        type: REVERIE_PARAMS,
        reverieParams: reverieParams,
    };
};

export const setProvider = (provider) => {
    return {
        type: LOGIN_PROVIDER,
        provider: provider,
    };
};

export const setPlayer = (player) => {
    return {
        type: SET_PLAYER,
        player
    }
}
