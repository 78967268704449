import React, { useImperativeHandle, useState } from "react";

const Icon = React.forwardRef((props, ref) => {

    const [loader, setLoader] = useState(false);

    useImperativeHandle(ref, () => {
        return {
            setLoader,
        }
    })

    if (loader) {
        return (
            <span style={{margin: "0 0.5rem"}} className="spinner-border spinner-border-sm" role="status" />
        )
    }

    if (props.onClick) {
        return (
            <a className={props.wrapperClassName} title={props.title} data-toggle="tooltip" onClick={props.onClick}>
                <i style={props.style} className={`icofont-${props.name} ${props.className}`} />
                {props.title}
            </a>
        )
    }

    return <i style={props.style} className={`icofont-${props.name} ${props.className}`} />
});

Icon.defaultProps = {
    className: '',
    name: "",
    wrapperStyle: {padding:0}
}

export default Icon;