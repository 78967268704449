import { SET_POST_DETAIL } from "../constants/post";

const initState = {
  postDetail: {},
};

export default (state = initState, action) => {
  switch (action.type) {
    case SET_POST_DETAIL:
      return {
        ...state,
        postDetail: action.postDetail
      }
    default:
      return state;
  }
};
