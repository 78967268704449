import {
    ADD_NOTIFICATION,
    GET_NOTIFICATIONS,
} from "../constants/notifications";

export const getNotification = (payload) => {
    return {
        type: GET_NOTIFICATIONS,
        payload,
    };
};

export const addNotification = (payload) => {
    return {
        type: ADD_NOTIFICATION,
        payload,
    };
};
