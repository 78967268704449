import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setFirebaseToken } from "src/redux/actions/app";

let app = null;

export const initFirebase = () => {
    const firebaseConfig = {
        apiKey: "AIzaSyDnHjindt2YcfGWbygsM4Wn3detw1jHLks",
        authDomain: "reverie-production.firebaseapp.com",
        projectId: "reverie-production",
        storageBucket: "reverie-production.appspot.com",
        messagingSenderId: "697408917565",
        appId: "1:697408917565:web:4c540c18bc360bb80b5a54",
        measurementId: "G-F8637CQ0Q8",
    };
    return initializeApp(firebaseConfig);
};

export const useFirebaseNotification = () => {
    const [messagingInstance, setMessagingInstance] = useState(null);
    const dispatch = useDispatch();

    const initMessaging = async (key) => {
        if (!("Notification" in window)) {
            throw console.log("This browser does not support desktop notification");
        }

        if (Notification.permission !== "denied" && Notification.permission !== "granted") {
            const permission = await Notification.requestPermission();
            if (permission !== "granted") {
                throw console.log("permission is denied");
            }
        }

        if (!("serviceWorker" in navigator)) {
            throw console.log("serviceWorker is not supported");
        }

        try {
            if (!app) app = initFirebase(key);

            const messaging = getMessaging(app);
            const token = await getToken(messaging, { vapidKey: "BG3hIaQcgk5jTmtbzpI7I5-OluqXaF5fHPWIB4eo54x4Qm2ECjwIfsVOY_kdntN8ZBi4gi4FuYmNOtr2bF1_ywU" });
            onMessage(messaging, (payload) => {
                // ...
            });
            dispatch(setFirebaseToken(token));
            setMessagingInstance(messaging);
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        initMessaging(window.FIREBASE_KEY);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return messagingInstance;
};
