import React, { useEffect, useImperativeHandle, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import './pageloader.module.css';

const PageLoader = React.forwardRef((props, ref) => {
    const [loader, setLoader] = useState(false);

    const showLoader = useSelector(store => store.app.showLoader);

    useImperativeHandle(ref, () => {
        return {
            setLoader
        }
    }, [])

    const renderLoaderItem = useMemo(() => {
        return [...Array(10).keys()].map(i => (
            <span key={i} className="loader-item" />
        ))
    } ,[])

    useEffect(() => {       
        setLoader(showLoader)
    }, [showLoader])

    return (
        <div className={`page-loader ${!loader ? "hidden" : ''}`}>
            <div className={"loader"}>
                {renderLoaderItem}
            </div>
        </div>
    );
});

export default PageLoader;