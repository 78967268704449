import { CLOSE_ALL_CHATS, CLOSE_USER_CHAT, SET_LATEST_MESSAGE, SET_USER_CHAT } from "../constants/messages";
import _ from "lodash";
import moment from "moment";

const initialState = {
    activeChats: {},
    latestMessage: {},
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_USER_CHAT:
            return {
                ...state,
                activeChats: {
                    ...state.activeChats,
                    [action.userIdName]: (state.activeChats[action.userIdName] ? _.uniqBy([...state.activeChats[action.userIdName], ...action.messages], "id") : action.messages)?.sort((a, b) =>
                        moment(b.created_at).diff(moment(a.created_at)),
                    ),
                },
            };
        case CLOSE_USER_CHAT:
            // eslint-disable-next-line no-case-declarations
            const chats = Object.assign({}, state.activeChats);
            delete chats[action.userIdName];
            return {
                ...state,
                activeChats: chats,
            };

        case SET_LATEST_MESSAGE:
            return {
                ...state,
                latestMessage: action.message,
            };

        case CLOSE_ALL_CHATS:
            return {};
        default:
            return state;
    }
};
