import { APP, FIREBASE_TOKEN, LOGIN_PROVIDER, REVERIE_PARAMS, SET_PLAYER, SHOW_LOADER, STRIPE_CLIENT_ID } from "../constants/app";

const initState = {
    app: "",
    firebaseToken: "",
    showLoader: false,
    stripe_client_id: "",
    reverieParams: {},
    provider: "",
    player: null, // current video player
};

export default (state = initState, action) => {
    switch (action.type) {
        case APP:
            return {
                ...state,
                app: action.app,
            };
        case FIREBASE_TOKEN:
            return {
                ...state,
                firebaseToken: action.firebaseToken,
            };
        case SHOW_LOADER:
            return {
                ...state,
                showLoader: action.showLoader,
            };

        case STRIPE_CLIENT_ID:
            return {
                ...state,
                stripe_client_id: action.stripe_client_id,
            };
        case REVERIE_PARAMS:
            return {
                ...state,
                reverieParams: action.reverieParams,
            };

        case LOGIN_PROVIDER:
            return {
                ...state,
                provider: action.provider,
            };

        case SET_PLAYER: 
            return {
            ...state,
            player: action.player
        }
        default:
            return state;
    }
};
