import React from "react";

const Error = (props) => {
    const { error, className } = props;

    return (
        <small className={`text-danger font-weight-bold d-block ${className}`} style={{ marginTop: props.marginTop }}>
            * {error}
        </small>
    );
};

export default Error;
