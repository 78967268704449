import React, { useEffect, useRef, useCallback, useMemo, useImperativeHandle } from "react";
import "./scrolllist.style.css";

const ScrollList = React.forwardRef((props, ref) => {
    const elementRef = useRef();
    const _canApi = useRef(true);
    const _lastScrollTop = useRef();
    const _page = useRef(0);
    const _debounceTime = useRef(props.debounce || 1000);
    const { data, renderItem, isReverse, renderOnEmptyList } = props;

    useImperativeHandle(ref, () => {
        return {
            set page(p) {
                _page.current = p;
            },
            get page() {
                return _page.current;
            },
            set debounce(d) {
                _debounceTime.current = d;
            },
        };
    });

    const onScroll = useCallback(
        (e) => {
            if (!props.hasMore) return;

            if (_canApi.current == true && e.target.scrollHeight - Math.abs(e.target.scrollTop) - props.threshold <= e.target.clientHeight) {
                if (props.onEndReach && _lastScrollTop.current < Math.abs(e.target.scrollTop)) {
                    props.onEndReach(e, _page.current);
                    _page.current = _page.current + 1;
                }
                _canApi.current = false;
                setTimeout(() => (_canApi.current = true), _debounceTime.current);
            }
            _lastScrollTop.current = Math.abs(e.target.scrollTop);
        },
        [props],
    );

    useEffect(() => {
        if (data?.length > 0) {
            const { parentElement } = elementRef.current;
            parentElement?.addEventListener("scroll", onScroll);
            // elementRef.current?.remove(); // Remove entry from DOM as it is polluting DOM heirarchy
            if (isReverse && _page.current == 0) parentElement?.scrollTo(0, parentElement?.scrollHeight);
            return () => {
                parentElement?.removeEventListener("scroll", onScroll);
            };
        }
    }, [onScroll, isReverse, data]);

    const renderedItems = useMemo(() => {
        if (data?.length) {
            return data?.map(renderItem);
        }
        return renderOnEmptyList;
    }, [data, renderItem, renderOnEmptyList]);

    return (
        <>
            <div ref={elementRef} style={{ display: "none" }} />
            {renderedItems}
        </>
    );
});

ScrollList.defaultProps = {
    threshold: 200,
    debounce: 500,
    isReverse: false,
    data: [],
    hasMore: false,
    renderItem: (e) => e,
    renderOnEmptyList: null,
};

export default ScrollList;
