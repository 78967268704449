import {
    CREATE_POST,
    DELETE_POST,
    POSTS,
    UPDATE_POST,
} from "../constants/posts";

const initialState = [];

export default (state = initialState, action) => {
    switch (action.type) {
        case POSTS:
            return action.payload;

        case DELETE_POST:
            return state.filter((p) => p.id !== action.payload);

        case CREATE_POST:
            return [...state, action.payload];
        case UPDATE_POST:
            return state.map((p) =>
                p.id === action.payload.id ? action.payload : p
            );

        default:
            return initialState;
    }
};
