import { LOGIN, SET_TOKEN, LOGOUT, NEEDS_LOGIN } from "../constants/user";

const initalState = {
    user: {},
    token: "",
    needs_login: false
}

const usersReducer = (state = initalState, action) => {
    switch (action.type) {
        case LOGIN:
            return {
                ...state,
                user: action.user,
            }       
        case SET_TOKEN:
            return {
                ...state,
                token: action.token,
            }
        case LOGOUT:
            return initalState;

        case NEEDS_LOGIN:
            return {
                ...state,
                needs_login: action.payload
            }
        default:
            return state
    }
}

export default usersReducer;